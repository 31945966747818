import { tracker } from '~/src/utils/tracker';

const track = tracker('Schedule Demo', {
  integrations: {
    All: false,
    Heap: true,
    'Customer.io': true,
    'Google Tag Manager': true,
    'Facebook Custom Audiences': true,
    'Facebook Pixel': true,
  },
});

export const trackDemoForm = (email) => {
  track('Demo form submitted', {
    pageUrl: window.location.href,
    email,
  });
};
