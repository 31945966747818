import styled, { css } from 'styled-components';
import Input, { INPUT_THEME_DARK } from '@uc-common/input';
import { Button } from '@uc-common/button';
import FormField, {
  StyledFormFieldLabel,
  StyledFormFieldErrorMessages,
} from '@uc-common/form-field';

export const FormContainer = styled.div(
  ({ theme: { tablet, desktop } }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;

    ${tablet()} {
      margin-bottom: 70px;
    }

    ${desktop()} {
      margin-bottom: 0;
    }
  `
);

export const FormBlock = styled.form(
  ({ theme: { tablet } }) => css`
    color: #fff;
    font-size: 14px;
    background-color: #000000;
    padding: 25px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 15px;
    border-radius: inherit;
    width: 100%;
    position: relative;

    ${tablet()} {
      grid-row-gap: 32px;
      grid-template-columns: 1fr 1fr;
      padding: 40px;
    }
  `
);

export const FormInputField = styled(FormField)(
  ({ theme: { laptop } }) => css`
    ${StyledFormFieldLabel} {
      font-size: 14px;
      line-height: 1.6;
      text-align: start;
      color: #bfcdd8;
    }

    ${StyledFormFieldErrorMessages} {
      ${laptop()} {
        max-width: 275px;
      }
    }
  `
);

export const FormFullInputField = styled(FormInputField)(
  ({ theme: { tablet } }) => css`
    ${tablet()} {
      grid-column: 1 / 3;
    }
  `
);

export const FormGradientBlock = styled.div(
  ({ theme: { laptop, desktop } }) => css`
    width: 100%;
    margin-bottom: 40px;
    padding: 1px;
    background: linear-gradient(
      to left top,
      rgba(55, 113, 255, 0.3085609243697479) 1%,
      rgba(52, 107, 242, 0.4458158263305322) 4%,
      rgba(19, 54, 128, 1) 69%,
      rgba(0, 178, 255, 1) 100%
    );
    border-radius: 12px;
    box-shadow: 0 0 15px rgba(1, 179, 255, 0.3);

    ${laptop()} {
      width: 85%;
    }
    ${desktop()} {
      width: 100%;
    }
  `
);

export const FormPrivacyAlert = styled.span(
  ({ theme: { tablet } }) => css`
    width: 80%;
    text-align: center;
    color: #76828c;
    font-size: 13px;
    line-height: 1.6;

    ${tablet()} {
      width: 100%;
      font-size: 14px;
    }
  `
);

export const FormSubmitButton = styled(Button)(
  ({ theme: { tablet } }) => css`
    width: max-content;
    min-width: 140px;
    margin: 10px auto 0;

    ${tablet()} {
      margin: 0 0 0 auto;
    }
  `
);

export const FormInput = styled(Input).attrs(() => ({
  themeDefinition: INPUT_THEME_DARK,
}))``;

export const FormTextarea = styled(FormInput).attrs(() => ({
  forwardedAs: 'textarea',
}))`
  resize: vertical;
  min-height: 135px;
`;

export const FormSuccessMessageWrapper = styled.div`
  background-color: #000000;
  border-radius: 12px;
  width: 100%;
  height: 100%;
  padding: 200px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const FormSuccessMessageTitle = styled.span(
  ({ theme: { tablet } }) => css`
    color: #ffffff;
    font-weight: 700;
    margin-bottom: 15px;
    text-align: center;
    font-size: 16px;

    ${tablet()} {
      font-size: 24px;
      margin-bottom: 10px;
    }
  `
);

export const FormSuccessMessageText = styled.span(
  ({ theme: { tablet } }) => css`
    color: #84919c;
    text-align: center;
    font-size: 14px;

    ${tablet()} {
      font-size: 15px;
    }
  `
);

export const FormErrorMessage = styled.p(
  ({ theme: { tablet }, $isVisible = false }) => css`
    order: 1;
    font-size: 14px;
    line-height: 1.5;
    color: #e4496f;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease-in-out;

    ${$isVisible &&
    css`
      opacity: 1;
      pointer-events: auto;
    `}

    ${tablet()} {
      order: 0;
      width: 275px;
    }
  `
);

export const FormSuccessMessage = styled.div(
  ({ $isVisible = false }) => css`
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    order: 1;
    opacity: 0;
    transform: translateY(20px);
    transition:
      opacity 0.3s ease-in-out,
      transform 0.3s ease-in-out;

    ${$isVisible &&
    css`
      opacity: 1;
      pointer-events: auto;
      transform: translateY(0);
      scale: 1;
    `}
  `
);
