export function isUrlValid(str, isProtoRequired = false) {
  const hasProto = str.includes('://');
  if (!hasProto && !isProtoRequired) {
    str = `http://${str}`;
  }

  try {
    const url = new URL(str);
    return url.protocol === 'http:' || url.protocol === 'https:';
  } catch (err) {
    return false;
  }
}
