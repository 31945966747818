import React from 'react';
import { Box } from 'rebass/styled-components';
import { Container as Wrapper } from '@uc-common/container';
import { Customers } from '@uc-common/b-customers';

import { mailToLink } from '~/src/utils/support-link';
import { SiteLayout } from '../components/Layout/SiteLayout';
import { ScreenReaderTitle } from '../components/ScreenReader';
import { Form } from '../components/forScheduleDemo';

import {
  ScheduleDemoSection,
  ScheduleDemoContainer,
  FormHeading,
  FormDescription,
  ContentContainer,
  AdvertContainer,
  QuoteContainer,
  Quote,
  QuoteAuthor,
  QuoteAuthorAvatar,
  QuoteAuthorInfo,
  QuoteAuthorName,
  QuoteAuthorStatus,
  CustomerSupportHeader,
  CustomerSupportText,
  CustomerSupportHelp,
  ScheduleDemoLink,
  CustomersContainer,
  CustomersContainerTitle,
  CustomersContainerTitleWrapper,
} from '../components/forScheduleDemo/styled-components';

export const frontmatter = {
  title: 'Book a demo call',
  description:
    'Get a 20-Minute live consultation and find out how Uploadcare can power up your business',
};

const ScheduleDemo = ({ location }) => (
  <SiteLayout meta={frontmatter} pathName={location.pathname} isDark>
    <ScheduleDemoContainer data-navbar-theme="dark">
      <Wrapper>
        <ScreenReaderTitle>Book a demo call</ScreenReaderTitle>

        <ScheduleDemoSection>
          <FormHeading>Talk to an Uploadcare expert</FormHeading>
          <FormDescription>
            We’ll help you find the right plan and pricing for your business.
          </FormDescription>

          <ContentContainer>
            <Form />
            <AdvertContainer>
              <QuoteContainer>
                <Quote>
                  “For us to build the functionality we use, it would probably take a team of 3 or 4
                  developers several months. Using Uploadcare, we’ve basically taken a shortcut.”
                </Quote>
                <QuoteAuthor>
                  <QuoteAuthorAvatar uuid="26dab211-afa9-4fcc-9196-028df9c9416d" />
                  <QuoteAuthorInfo>
                    <QuoteAuthorName>Finbarr Taylor</QuoteAuthorName>
                    <QuoteAuthorStatus>Co-founder, Shogun</QuoteAuthorStatus>
                  </QuoteAuthorInfo>
                </QuoteAuthor>
              </QuoteContainer>
              <Box>
                <CustomerSupportHeader>Looking for customer support?</CustomerSupportHeader>
                <CustomerSupportText>
                  We’re here to help! Get in touch if you have technical, account, or billing
                  questions.
                </CustomerSupportText>
                <CustomerSupportHelp>
                  Visit{' '}
                  <ScheduleDemoLink to={mailToLink.help.href}>Uploadcare Support</ScheduleDemoLink>
                </CustomerSupportHelp>
              </Box>
            </AdvertContainer>
          </ContentContainer>
        </ScheduleDemoSection>
        <CustomersContainer>
          <CustomersContainerTitleWrapper>
            <CustomersContainerTitle>Trusted by the best developer teams</CustomersContainerTitle>
          </CustomersContainerTitleWrapper>
          <Customers />
        </CustomersContainer>
      </Wrapper>
    </ScheduleDemoContainer>
  </SiteLayout>
);

export default ScheduleDemo;
