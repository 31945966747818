import React from 'react';
import { Box } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import { PaddingEnum } from '../b-layout';
import { BuzzLogoGrid, BuzzLogoIdEnum } from '../buzz-logo';

export const Customers = ({ sx }) => {
  const ids = [
    BuzzLogoIdEnum.WEBFLOW,
    BuzzLogoIdEnum.PREZLY_BW,
    BuzzLogoIdEnum.MOZILLA,
    BuzzLogoIdEnum.TOTANGO_BW,
    BuzzLogoIdEnum.LOREAL,
    BuzzLogoIdEnum.LAPHIL,
  ];

  const widths = [[87], [112], [84], [130], [96], [84]];

  const logoSx = [
    { gridArea: ['initial'] },
    { gridArea: ['3 / 1', 'initial'] },
    { gridArea: ['initial', '1 / 2', 'initial'] },
    { gridArea: ['1 / 2', 'initial'] },
    { gridArea: ['2 / 2', '1 / 3', 'initial'] },
    { gridArea: ['initial'] },
  ];

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        px: PaddingEnum.HORIZONTAL_NORMAL,
        ...sx,
      }}
    >
      <BuzzLogoGrid
        ids={ids}
        widths={widths}
        containerSx={{
          display: 'grid',
          gridAutoFlow: ['row', 'row', 'column'],
          gridTemplateColumns: ['repeat(2, 1fr)', 'repeat(3, 1fr)', 'auto'],
        }}
        logoSx={logoSx.map((sx) => ({ ...sx, px: 0, minHeight: 64 }))}
      />
    </Box>
  );
};

Customers.propTypes = {
  sx: PropTypes.object,
};
