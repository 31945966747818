import React from 'react';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { ButtonTypeEnum } from '@uc-common/button';
import { FORM_ID_ENUM, useLeadForm, FormStatusTypeEnum } from '@uc-common/use-lead-form';
import InputSelect, { INPUT_SELECT_THEME_DARK } from '@uc-common/input-select';
import { LeadFormCaptchaField } from '@uc-common/b-leadform';

import { isEmailValid, isUrlValid } from '~/src/utils/validation';
import { mailToLink } from '~/src/utils/support-link';
import { ScheduleDemoLink } from '../../styled-components';
import {
  FormContainer,
  FormGradientBlock,
  FormBlock,
  FormInputField,
  FormInput,
  FormSubmitButton,
  FormPrivacyAlert,
  FormTextarea,
  FormSuccessMessageWrapper,
  FormSuccessMessageTitle,
  FormSuccessMessageText,
  FormSuccessMessage,
  FormErrorMessage,
  FormFullInputField,
} from './styles/Form.styles';

import { BlueLink } from '../../../Typography';
import { trackDemoForm } from './utils';

export const Form = () => {
  const methods = useForm();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setError,
  } = methods;

  const { run, status, validationErrors } = useLeadForm(FORM_ID_ENUM.DEMO_REQUEST, setError);

  const isShowError = status === FormStatusTypeEnum.REJECTED && !validationErrors;
  const isSuccess = status === FormStatusTypeEnum.FULFILLED;
  const requiredInputOptions = {
    required: 'Please, fill in the field.',
  };

  const onSubmit = async (formData) => {
    const data = {
      ...formData,
      url: window.location.href,
    };

    await run(data);
    trackDemoForm(formData.email);
    window.dataLayer?.push({ event: 'schedule_demo_submitted' });
  };

  return (
    <FormContainer>
      <FormGradientBlock>
        <FormProvider {...methods}>
          <FormBlock onSubmit={handleSubmit(onSubmit)}>
            <FormInputField name="email" label="Your email" errors={errors}>
              <FormInput
                name="email"
                placeholder="janedoe@acme.com"
                {...register('email', {
                  ...requiredInputOptions,
                  validate: (email) =>
                    isEmailValid(email) || 'Please, enter a valid email address.',
                })}
              />
            </FormInputField>
            <FormInputField name="name" label="Your name" errors={errors}>
              <FormInput
                name="name"
                placeholder="Jane Doe"
                {...register('name', requiredInputOptions)}
              />
            </FormInputField>
            <FormInputField name="website" label="Company website" errors={errors}>
              <FormInput
                name="website"
                placeholder="acme.com"
                {...register('website', {
                  ...requiredInputOptions,
                  validate: (url) => isUrlValid(url) || 'Please, enter a valid URL.',
                })}
              />
            </FormInputField>
            <FormInputField name="size" label="Company size" errors={errors}>
              <Controller
                render={({ field, fieldState }) => (
                  <InputSelect
                    {...field}
                    themeDefinition={INPUT_SELECT_THEME_DARK}
                    options={[
                      { value: '1-10', label: '1-10 Employees' },
                      { value: '11-50', label: '11-50 Employees' },
                      { value: '51-200', label: '51-200 Employees' },
                      { value: '201-500', label: '201-500 Employees' },
                      { value: '501-1000', label: '501-1000 Employees' },
                      { value: '1001-5000', label: '1001-5000 Employees' },
                      { value: '5001', label: '5001+ Employees' },
                    ]}
                    hasError={Boolean(fieldState.error)}
                  />
                )}
                name="size"
                placeholder="--"
                rules={requiredInputOptions}
                control={control}
              />
            </FormInputField>
            <FormFullInputField name="about" label="How can we help you?" errors={errors}>
              <FormTextarea
                name="about"
                placeholder="What are you building?"
                {...register('about')}
              />
            </FormFullInputField>

            {/* IDK how to align to the right captcha and submit button properly, so put this div here */}
            <div />

            <LeadFormCaptchaField theme="dark" />

            <FormErrorMessage $isVisible={isShowError}>
              {isShowError && (
                <>
                  Something went wrong. Please contact us at{' '}
                  <BlueLink to={mailToLink.help.href}>{mailToLink.help.text}</BlueLink>.
                </>
              )}
            </FormErrorMessage>

            <FormSubmitButton
              type={ButtonTypeEnum.PRIMARY}
              htmlType="submit"
              isLoading={status === FormStatusTypeEnum.PENDING}
            >
              Submit
            </FormSubmitButton>
            <FormSuccessMessage $isVisible={isSuccess}>
              {isSuccess && (
                <FormSuccessMessageWrapper>
                  <FormSuccessMessageTitle>Your message has been sent</FormSuccessMessageTitle>
                  <FormSuccessMessageText>
                    Thanks for getting in touch, we will reach out shortly.
                  </FormSuccessMessageText>
                </FormSuccessMessageWrapper>
              )}
            </FormSuccessMessage>
          </FormBlock>
        </FormProvider>
      </FormGradientBlock>
      <FormPrivacyAlert>
        By submitting this form, I confirm that I have read and understood the Uploadcare&nbsp;
        <ScheduleDemoLink to="/about/privacy-policy/" target="_blank">
          Privacy Policy
        </ScheduleDemoLink>
        .
      </FormPrivacyAlert>
    </FormContainer>
  );
};
