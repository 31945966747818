import styled, { css } from 'styled-components';
import Image from '@uc-common/image';

import { SectionWrapper } from '../CommonStyles';
import { SectionHeading } from '../Typography';
import { Link } from '../Link';

export const ScheduleDemoSection = styled(SectionWrapper)`
  background-color: #000000;
  overflow: hidden;
  padding: 0;
  width: 100%;
`;

export const ScheduleDemoContainer = styled.section(
  ({ theme: { tablet, laptop } }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #000000;
    padding: 30px 0 70px 0;

    ${tablet()} {
      padding: 95px 0 80px 0;
    }

    ${laptop()} {
      padding: 95px 0 150px 0;
    }
  `
);

export const FormHeading = styled(SectionHeading)(
  ({ theme: { tablet, laptop } }) => css`
    font-size: 30px;
    line-height: 1.2;
    text-align: center;
    letter-spacing: -0.025em;
    color: #ffffff;
    margin-bottom: 20px;

    ${tablet()} {
      font-size: 40px;
    }

    ${laptop()} {
      font-size: 56px;
    }
  `
);

export const FormDescription = styled.p(
  ({ theme: { tablet, laptop } }) => css`
    font-size: 16px;
    line-height: 1.6;
    text-align: center;
    color: #84919c;
    margin-bottom: 25px;

    ${tablet()} {
      font-size: 20px;
      margin-bottom: 60px;
    }

    ${laptop()} {
      margin-bottom: 82px;
      font-size: 22px;
    }
  `
);

export const ContentContainer = styled.div(
  ({ theme: { tablet, desktop } }) => css`
    width: 100%;
    display: grid;
    margin-bottom: 80px;

    ${tablet()} {
      margin-bottom: 120px;
    }

    ${desktop()} {
      margin-bottom: 130px;
      grid-gap: 50px;
      grid-template-columns: 1fr 390px;
      align-items: flex-start;
    }
  `
);

export const AdvertContainer = styled.div(
  ({ theme: { tablet, desktop } }) => css`
    display: grid;
    grid-gap: 40px;
    margin: 0 15px;

    ${tablet()} {
      margin: 0 auto;
    }

    ${desktop()} {
      margin: 0;
      padding-top: 20px;
    }
  `
);

export const QuoteContainer = styled.div(
  ({ theme: { tablet } }) => css`
    display: grid;
    grid-gap: 20px;
    color: #ffffff;
    padding-bottom: 30px;
    border-bottom: 1px solid #232933;

    ${tablet()} {
      padding-bottom: 40px;
    }
  `
);

export const Quote = styled.p(
  ({ theme: { tablet, laptop } }) => css`
    font-size: 18px;
    text-align: left;
    line-height: 1.5;

    ${tablet()} {
      font-size: 20px;
    }

    ${laptop()} {
      font-size: 22px;
    }
  `
);
export const QuoteAuthor = styled.div`
  display: flex;
  align-items: center;
`;
export const QuoteAuthorAvatar = styled(Image)(
  ({ theme: { laptop } }) => css`
    border-radius: 50%;
    height: 40px;
    width: 40px;
    margin-right: 15px;

    ${laptop()} {
      height: 52px;
      width: 52px;
    }
  `
);

export const QuoteAuthorInfo = styled.div(
  ({ theme: { laptop } }) => css`
    font-size: 14px;
    display: grid;
    line-height: 1.6;

    ${laptop()} {
      font-size: 16px;
    }
  `
);
export const QuoteAuthorName = styled.span`
  font-weight: 700;
`;

export const QuoteAuthorStatus = styled.span(
  ({ theme: { laptop } }) => css`
    color: #84919c;
    font-size: 13px;

    ${laptop()} {
      font-size: 15px;
    }
  `
);

export const CustomerSupportHeader = styled.span(
  ({ theme: { laptop } }) => css`
    color: #ffffff;
    font-weight: 700;
    margin-bottom: 10px;
    line-height: 160%;

    ${laptop()} {
      font-size: 16px;
    }
  `
);

export const CustomerSupportText = styled.p(
  ({ theme: { laptop } }) => css`
    color: #84919c;
    margin-bottom: 15px;
    font-size: 13px;

    ${laptop()} {
      font-size: 15px;
    }
  `
);

export const CustomerSupportHelp = styled.p(
  ({ theme: { laptop } }) => css`
    color: #84919c;
    font-size: 13px;

    ${laptop()} {
      font-size: 15px;
    }
  `
);

export const ScheduleDemoLink = styled(Link)(
  ({ theme: { laptop } }) => css`
    color: #3771ff;

    ${laptop()} {
      font-size: 15px;
    }

    &:hover {
      text-decoration: underline;
    }
  `
);

export const CustomersContainer = styled.div`
  display: grid;
  grid-gap: 30px;
  align-items: center;
  width: 100%;
`;

export const CustomersContainerTitle = styled.span`
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.1em;
  color: #84919c;
  text-transform: uppercase;
  text-align: center;
`;

export const CustomersContainerTitleWrapper = styled.div(
  ({ theme: { tablet } }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${tablet()} {
      flex-direction: row;
    }
  `
);
